.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.accordion{
  --cui-accordion-color: #{$accordion-color};
  --cui-accordion-bg: #{$accordion-bg};
  --cui-accordion-transition: #{$accordion-transition};
  --cui-accordion-border-color: #{$accordion-border-color};
  --cui-accordion-border-width: #{$accordion-border-width};
  --cui-accordion-border-radius: #{$accordion-border-radius};
  --cui-accordion-inner-border-radius: #{$accordion-inner-border-radius};
  --cui-accordion-btn-padding-x: #{$accordion-button-padding-x};
  --cui-accordion-btn-padding-y: #{$accordion-button-padding-y};
  --cui-accordion-btn-color: #{$accordion-button-color};
  --cui-accordion-btn-bg: #{$accordion-button-bg};
  --cui-accordion-btn-icon: #{escape-svg($accordion-button-icon)};
  --cui-accordion-btn-icon-width: #{$accordion-icon-width};
  --cui-accordion-btn-icon-transform: #{$accordion-icon-transform};
  --cui-accordion-btn-icon-transition: #{$accordion-icon-transition};
  --cui-accordion-btn-active-icon: #{escape-svg($accordion-button-active-icon)};
  --cui-accordion-btn-focus-border-color: #{$accordion-button-focus-border-color};
  --cui-accordion-btn-focus-box-shadow: #{$accordion-button-focus-box-shadow};
  --cui-accordion-body-padding-x: #{$accordion-body-padding-x};
  --cui-accordion-body-padding-y: #{$accordion-body-padding-y};
  --cui-accordion-active-color: #{$accordion-button-active-color};
  --cui-accordion-active-bg: #{$accordion-button-active-bg};
}